// MainContent.tsx

import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArenaModal from "../common/ArenaModal";
import axiosURLS from "../services/Api";
import { RiContactsBook2Line } from "react-icons/ri";
import { Formik, Form, ErrorMessage } from "formik";
import Input from "../common/InputCustom";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaAngleDown } from "react-icons/fa";
import * as Yup from "yup"; // Import Yup for validation
import ConfirmModal from "../common/ConfirmModal";
import { motion } from "framer-motion";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import moment from 'moment-timezone';

interface SlotAvailability {
  totalSlots: number;
  availableSlots: number;
}

interface BookingDetails {
  id: string;
  name: string;
  mobile: string;
  startTime: string;
}

const initialValues = {
  fullName: "",
  mobileNumber: "+91",
  email: "",
  bookingTime: "",
  id: "",
  name: "",
};

const MainContent = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenSubmit, setIsOpenSubmit] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<BookingDetails>();
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [selectedTime, setSelectedTime] = useState("");
  const [formatDate, setFormatDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate] = useState<string>(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
  const [displayFromDate] = useState<string>(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[currentDate.getMonth()];
    return `${day}/${monthName}/${year}`;
  });
  const [toDate] = useState<string>(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const lastDate = new Date(year, parseInt(month), 0).getDate();
    return `${year}-${month}-${lastDate}`;
  });
  const [availabilityData, setAvailabilityData] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nextMonthDate, setNextMonthDate] = useState<any>();
  const [phone, setPhone] = useState("");
  const [firstEffectCompleted, setFirstEffectCompleted] = useState(false);
  const [slotwiseAvailability, setSlotwiseAvailability] = useState<{
    [key: string]: SlotAvailability;
  }>({});
  const [bookedForMinutes, setBookedForMinutes] = useState(0);
  const [selectedSlots, setSelectedSlots] = useState<any>([]);
  const [price, setPrice] = useState<any>([]);

  const currentTime: Date = new Date();
  const currentHour = currentTime.getHours();

  const closeModal = () => setIsOpenSubmit(false);
  // function getSelectedOptionFromStorage() {
  //   const storedOption = sessionStorage.getItem("selectedOption");
  //   return storedOption ? JSON.parse(storedOption) : null;
  // }

  // useEffect(() => {
  //   const storedOption = getSelectedOptionFromStorage();
  //   if (storedOption) {
  //     setSelectedOption(storedOption);
  //     setIsOpen(false);
  //   }
  // }, []);

  // Function to handle selected option from modal
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    // sessionStorage.setItem("selectedOption", JSON.stringify(option)); // Save selected option to local storage
    setIsOpen(false); // Close the modal when an option is selected
  };

  // Function to handle cancel button click
  // const handleCancel = () => {
  //   setIsOpen(false); // Close the modal
  // };

  const getDataForSelectedDate = (
    selectedDate: any
  ): { [key: string]: SlotAvailability } => {
    return availabilityData[selectedDate] || {};
  };

  function getNumericSlot(
    slot: string,
    availability: SlotAvailability
  ): { numericSlot: number; totalSlots: number; availableSlots: number } {
    switch (slot) {
      case "TEN_AM_TO_ELEVEN_AM":
        return {
          numericSlot: 10,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "ELEVEN_AM_TO_TWELVE_PM":
        return {
          numericSlot: 11,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "TWELVE_PM_TO_ONE_PM":
        return {
          numericSlot: 12,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "ONE_PM_TO_TWO_PM":
        return {
          numericSlot: 13,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "TWO_PM_TO_THREE_PM":
        return {
          numericSlot: 14,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "THREE_PM_TO_FOUR_PM":
        return {
          numericSlot: 15,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "FOUR_PM_TO_FIVE_PM":
        return {
          numericSlot: 16,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "FIVE_PM_TO_SIX_PM":
        return {
          numericSlot: 17,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "SIX_PM_TO_SEVEN_PM":
        return {
          numericSlot: 18,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "SEVEN_PM_TO_EIGHT_PM":
        return {
          numericSlot: 19,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "EIGHT_PM_TO_NINE_PM":
        return {
          numericSlot: 20,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "NINE_PM_TO_TEN_PM":
        return {
          numericSlot: 21,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "TEN_PM_TO_ELEVEN_PM":
        return {
          numericSlot: 22,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      case "ELEVEN_PM_TO_TWELVE_AM":
        return {
          numericSlot: 23,
          totalSlots: availability.totalSlots,
          availableSlots: availability.availableSlots,
        };
      default:
        return { numericSlot: NaN, totalSlots: 0, availableSlots: 0 }; // Return NaN if no matching slot is found
    }
  }

  const handleDateSelect = (date: any) => {
    setSelectedTime("");

    setSelectedSlots([]);
    if (!date) {
      // Handle the case when no date is selected
      console.error("No date selected");
      return;
    }

    setSelectedDate(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setFormatDate(formattedDate);

    if (!selectedOption) {
      return; // Exit early if no arena is selected
    }
    const selectedData = getDataForSelectedDate(formattedDate);

    const updatedSlotwiseAvailability: { [key: string]: SlotAvailability } = {};

    if (selectedData && selectedData.slotwiseAvailability) {
      for (const [slot, availability] of Object.entries(
        selectedData.slotwiseAvailability
      )) {
        const { numericSlot, totalSlots, availableSlots } = getNumericSlot(
          slot,
          availability
        );
        if (!isNaN(numericSlot)) {
          updatedSlotwiseAvailability[String(numericSlot)] = {
            totalSlots,
            availableSlots,
          };
        }
      }

      setSlotwiseAvailability(updatedSlotwiseAvailability);
    }
  };

  const displayFormatDate = (dateString: string) => {
    console.log("date", dateString);
    const [year, month, day] = dateString.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${day}/${monthName}/${year}`;
  };

  useEffect(() => {
    if (!isOpen && selectedOption) {
      axiosURLS
        .post("/onlineBooking/getAvailability", {
          fromDate: fromDate,
          toDate: toDate,
          arenaId: selectedOption.id,
        })
        .then((response) => {
          setAvailabilityData(response.data.bookingsAvailabilityInfo);
          setFirstEffectCompleted(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching availability data:", error);
        });
      axiosURLS
        .post("/pricing/search", {
          arenaId: selectedOption.name,
        })
        .then((response) => {
          setPrice(response.data);
        })
        .catch((error) => {
          console.log("Error fetching availability data:", error);
        });
    }
  }, [isOpen, selectedOption]);

  useEffect(() => {
    if (firstEffectCompleted) {
      const selectedData = getDataForSelectedDate(fromDate);

      const updatedSlotwiseAvailability: { [key: string]: SlotAvailability } =
        {};

      if (selectedData && selectedData.slotwiseAvailability) {
        for (const [slot, availability] of Object.entries(
          selectedData.slotwiseAvailability
        )) {
          const { numericSlot, totalSlots, availableSlots } = getNumericSlot(
            slot,
            availability
          );
          if (!isNaN(numericSlot)) {
            updatedSlotwiseAvailability[String(numericSlot)] = {
              totalSlots,
              availableSlots,
            };
          }
        }
        setSlotwiseAvailability(updatedSlotwiseAvailability);
      }
    }
  }, [firstEffectCompleted, fromDate]);

  const getPricingByType = (price: any, type: any) => {
    return price
      .filter((item: any) => item.pricingType === type)
      .map((item: any) => ({
        amount: item.amount,
        fromTime: item.fromTime,
        toTime: item.toTime,
      }));
  };

  const normalPricing = getPricingByType(price, "NORMAL");
  const weekendPricing = getPricingByType(price, "WEEKEND");
  const specialPricing = getPricingByType(price, "SPECIAL");

  const formatTime = (time: string): string => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${String(minutes).padStart(2, "0")} ${period}`;
  };

  const handleSelectedTime = (event: any) => {
    const selectedSlot = event.target.value;
    const remainingSlots = slotwiseAvailability[selectedSlot]?.availableSlots;

    const isContinuous = (slots: string[]) => {
      for (let i = 1; i < slots.length; i++) {
        if (parseInt(slots[i]) !== parseInt(slots[i - 1]) + 1) {
          return false;
        }
      }
      return true;
    };

    if (remainingSlots && remainingSlots > 0) {
      if (selectedSlots.includes(selectedSlot)) {
        const updatedSelectedSlots = selectedSlots.filter(
          (slot: any) => slot !== selectedSlot
        );
        updatedSelectedSlots.sort(
          (a: string, b: string) => parseInt(a) - parseInt(b)
        );

        if (!isContinuous(updatedSelectedSlots)) {
          setSelectedSlots([]);
          setBookedForMinutes(0);
          setSelectedTime("");
          toast("Please select time slots in sequence.");
        } else {
          setSelectedSlots(updatedSelectedSlots);
          setSelectedTime(updatedSelectedSlots[0] || "");
          setBookedForMinutes(updatedSelectedSlots.length * 60);
        }
      } else {
        const lastSelectedSlot =
          selectedSlots.length > 0
            ? parseInt(selectedSlots[selectedSlots.length - 1])
            : null;
        const currentSlot = parseInt(selectedSlot);

        if (
          lastSelectedSlot === null ||
          currentSlot === lastSelectedSlot + 1 ||
          currentSlot === lastSelectedSlot - 1
        ) {
          const updatedSelectedSlots = [...selectedSlots, selectedSlot];
          updatedSelectedSlots.sort(
            (a: string, b: string) => parseInt(a) - parseInt(b)
          );

          if (!isContinuous(updatedSelectedSlots)) {
            setSelectedSlots([]);
            setBookedForMinutes(0);
            setSelectedTime("");
            toast("Please select time slots in sequence.");
          } else {
            setSelectedSlots(updatedSelectedSlots);
            setSelectedTime(updatedSelectedSlots[0]);
            setBookedForMinutes(updatedSelectedSlots.length * 60);
          }
        } else {
          toast("Please select slots in sequence.");
        }
      }
    } else {
      toast("No slots available for this time slot", {
        icon: "😔",
      });
    }
  };

  //this function is only work for special days
  function isSpecialDay(date: any) {
    // Get the year, month, and day of the given date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();

    // Format the full date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    // Check if the formatted date matches any special day in prices array
    const match = price.find((price: any) => price.date === formattedDate);
    if (match) {
      return true;
    } else {
      return false;
    }
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(
        /^[a-zA-Z\s]*$/,
        "Name can't contain numbers or special characters"
      )
      .min(2, "Your Name is too Short!")
      .max(25, "Your Name is too Long!")
      .required("Name is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
  });

  return (
    <>
      <Navbar />

      <div
        className="flex flex-col items-center fixed left-[40%] md:left-[46%] mt-2  text-lg z-20"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <h1 className="font-md text-[#fded21]">Choose Arena</h1>
        {!isOpen && (
          <div className="flex items-center">
            <button className="p-1 font-md text-[#fded21]">
              {selectedOption.name ? selectedOption.name : ""}
            </button>
            <span
              className={`text-white ml-1 ${
                selectedOption.name ? "" : "italic"
              }`}
            >
              <FaAngleDown />
            </span>
          </div>
        )}
      </div>

      <motion.div
        className="background-image  min-h-[100vh] grid grid-cols-1 gap-5 pt-24 pb-10  md:mx-auto  md:grid-cols-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className=" flex flex-col col-span-4 ">
          <div className="md:flex">
            <div className="">
              <h1 className="text-lg font-semibold mb-2 text-center m-4">
                Select Date
              </h1>
              <div className="mb-4 px-10">
                <Calendar
                  className="border rounded p-2  w-screen"
                  onChange={(value) => handleDateSelect(value)}
                  value={selectedDate}
                  minDate={new Date()}
                  onActiveStartDateChange={({ activeStartDate, view }) => {
                    if (view === "month") {
                      // Check if the active start date has changed to the next month
                      // You can add your logic here
                      setNextMonthDate(activeStartDate);
                    }
                  }} // Disable dates before the current date
                />
              </div>
            </div>

            <div className=" px-8  w-full">
              <div className="flex justify-between m-4">
                <h1 className="text-lg font-semibold text-center">
                  Available Slots
                </h1>
                <h1 className="font-bold ">
                  {selectedDate.getDate() === currentTime.getDate() &&
                  selectedDate.getMonth() === currentTime.getMonth()
                    ? displayFromDate
                      ? displayFromDate
                      : displayFormatDate(formatDate)
                    : displayFormatDate(formatDate)}
                </h1>
              </div>
              {isOpen || (
                <div>
                  {selectedDate.getDate() === currentTime.getDate() && (
                    <div className="grid grid-cols-2 sm:grid-cols-2 gap-5">
                      {isLoading ? (
                        Array.from({ length: 10 }).map((_, index) => (
                          <div key={index} className="px-2 py-1">
                            <SkeletonTheme
                              baseColor="#cac5c5"
                              highlightColor="#ada8a8"
                            >
                              <Skeleton height={30} />
                            </SkeletonTheme>
                          </div>
                        ))
                      ) : Object.entries(slotwiseAvailability).length === 0 ? (
                        <div className="flex justify-center items-center w-96 h-72">
                          <div className="loader"></div>
                        </div>
                      ) : Object.entries(slotwiseAvailability).every(
                          ([slot, { availableSlots }]) =>
                            (selectedDate.getDay() !== 0 &&
                              selectedDate.getDay() !== 6 &&
                              parseInt(slot, 10) >= 23) ||
                            selectedDate.getDay() === 0 ||
                            (selectedDate.getDay() === 6 &&
                              parseInt(slot, 10) >= 24) ||
                            availableSlots === 0
                        ) ? (
                        <div className="flex justify-center items-center w-96 h-72">
                          <div className="text-red-600 font-bold text-center p-10">
                            All bays are fully booked today. Please select the
                            next available date to reserve your bay.
                          </div>
                        </div>
                      ) : (
                        Object.entries(slotwiseAvailability).map(
                          ([slot, { availableSlots }]) => {
                            if (
                              selectedDate.getMonth() ===
                                currentTime.getMonth() &&
                              currentHour >= parseInt(slot, 10)
                            ) {
                              return null; // Skip rendering the time slot if it's before 3 PM
                            }
                            if (
                              selectedDate.getDay() !== 0 &&
                              selectedDate.getDay() !== 6 &&
                              parseInt(slot, 10) === 23
                            ) {
                              return null; // Skip the slot "11:00 PM - 12:00 AM" on weekdays
                            }
                            return (
                              <div
                                className={`px-2 py-1 rounded flex items-center bg-slate-100 ${
                                  availableSlots === 0
                                    ? "border-b-2 border-red-500 shadow-md"
                                    : "border-b-2 border-green-500 shadow-md"
                                }`}
                                key={slot}
                              >
                                <label className="flex flex-col gap-1">
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="timeSlot"
                                      value={slot}
                                      onClick={handleSelectedTime}
                                      checked={selectedSlots.includes(slot)}
                                    />
                                    <span className="ml-2">
                                      {parseInt(slot, 10) === 23
                                        ? "11:00 PM - 12:00 AM"
                                        : parseInt(slot, 10) >= 12
                                        ? `${
                                            (parseInt(slot, 10) - 12) % 12 || 12
                                          }:00 PM - ${
                                            (parseInt(slot, 10) + 1 - 12) %
                                              12 || 12
                                          }:00 PM`
                                        : `${
                                            parseInt(slot, 10) % 12 || 12
                                          }:00 AM - ${
                                            (parseInt(slot, 10) + 1) % 12 || 12
                                          }:00 AM`}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          }
                        )
                      )}
                    </div>
                  )}

                  {/* {selectedDate.getDate() !== currentTime.getDate() && (
                    <div className="grid grid-cols-2 sm:grid-cols-2 gap-5">
                      {isLoading ? (
                        Array.from({ length: 10 }).map((_, index) => (
                          <div key={index} className="px-2 py-1">
                            <SkeletonTheme
                              baseColor="#cac5c5"
                              highlightColor="#ada8a8"
                            >
                              <Skeleton height={30} />
                            </SkeletonTheme>
                          </div>
                        ))
                      ) : Object.entries(slotwiseAvailability).length === 0 ? (
                        <div>No time slots available for the selected date</div>
                      ) : (
                        Object.entries(slotwiseAvailability).map(
                          ([slot, { availableSlots }]) => {
                            if (parseInt(slot, 10) < 15) {
                              return null; // Skip rendering the time slot if it's before 3 PM
                            }
                            return (
                              <div
                                className={`px-2 py-1 rounded flex items-center bg-slate-100 ${
                                  availableSlots === 0
                                    ? "border-b-2 border-red-500 shadow-md"
                                    : "border-b-2 border-green-500 shadow-md"
                                }`}
                                key={slot}
                              >
                                <label className="flex flex-col gap-1">
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="timeSlot"
                                      value={slot}
                                      onClick={handleSelectedTime}
                                      checked={selectedSlots.includes(slot)}
                                    />
                                    <span className="ml-2">
                                      {parseInt(slot, 10) >= 12
                                        ? `${
                                            (parseInt(slot, 10) - 12) % 12 || 12
                                          }:00 PM`
                                        : `${
                                            parseInt(slot, 10) % 12 || 12
                                          }:00 AM`}{" "}
                                      -{" "}
                                      {parseInt(slot, 10) + 1 >= 12
                                        ? `${
                                            (parseInt(slot, 10) + 1 - 12) %
                                              12 || 12
                                          }:00 PM`
                                        : `${
                                            (parseInt(slot, 10) + 1) % 12 || 12
                                          }:00 AM`}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          }
                        )
                      )}
                    </div>
                  )} */}
                  {selectedDate.getDate() !== currentTime.getDate() && (
                    <div className="grid grid-cols-2 sm:grid-cols-2 gap-5">
                      {isLoading ? (
                        Array.from({ length: 10 }).map((_, index) => (
                          <div key={index} className="px-2 py-1">
                            <SkeletonTheme
                              baseColor="#cac5c5"
                              highlightColor="#ada8a8"
                            >
                              <Skeleton height={30} />
                            </SkeletonTheme>
                          </div>
                        ))
                      ) : Object.entries(slotwiseAvailability).length === 0 ? (
                        <div>No time slots available for the selected date</div>
                      ) : (
                        Object.entries(slotwiseAvailability).map(
                          ([slot, { availableSlots }]) => {
                            if (parseInt(slot, 10) < 15) {
                              return null; // Skip rendering the time slot if it's before 3 PM
                            }
                            if (
                              selectedDate.getDay() !== 0 &&
                              selectedDate.getDay() !== 6 &&
                              parseInt(slot, 10) === 23
                            ) {
                              return null; // Skip the slot "11:00 PM - 12:00 AM" on weekdays
                            }
                            return (
                              <div
                                className={`px-2 py-1 rounded flex items-center bg-slate-100 ${
                                  availableSlots === 0
                                    ? "border-b-2 border-red-500 shadow-md"
                                    : "border-b-2 border-green-500 shadow-md"
                                }`}
                                key={slot}
                              >
                                <label className="flex flex-col gap-1">
                                  <div>
                                    <input
                                      type="checkbox"
                                      name="timeSlot"
                                      value={slot}
                                      onClick={handleSelectedTime}
                                      checked={selectedSlots.includes(slot)}
                                    />
                                    <span className="ml-2">
                                      {parseInt(slot, 10) === 23
                                        ? "11:00 PM - 12:00 AM"
                                        : parseInt(slot, 10) >= 12
                                        ? `${
                                            (parseInt(slot, 10) - 12) % 12 || 12
                                          }:00 PM - ${
                                            (parseInt(slot, 10) + 1 - 12) %
                                              12 || 12
                                          }:00 PM`
                                        : `${
                                            parseInt(slot, 10) % 12 || 12
                                          }:00 AM - ${
                                            (parseInt(slot, 10) + 1) % 12 || 12
                                          }:00 AM`}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          }
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {price.length > 0 && (
            <div className="md:flex gap-8 mt-8 px-10 ">
              <div className="md:w-[35em] w-full bg-gradient-to-b from-white via-pink-100 to-pink-100 shadow-lg rounded-lg px-4 py-2 border border-pink-400 my-3">
                <h2 className="text-xl font-semibold mb-2 text-pink-700">
                  Normal Pricing
                </h2>
                {/* Render normal pricing */}
                {normalPricing.map((item: any) => (
                  <div key={item.id} className="mb-2 flex justify-between">
                    <p className="text-gray-700">
                      Amount: <span className="font-bold">{item.amount}</span>
                    </p>
                    <p className="text-gray-500">
                      Time: {formatTime(item.fromTime)} -{" "}
                      {formatTime(item.toTime)}
                    </p>
                  </div>
                ))}
              </div>

              {/* Conditionally render Special Pricing based on selected date */}
              {isSpecialDay(selectedDate) && (
                <div className="md:w-[35em] w-full bg-gradient-to-b from-white via-pink-100 to-pink-100 shadow-lg rounded-lg px-4 py-2 border border-pink-400 my-3">
                  <h2 className="text-xl font-semibold mb-2 text-pink-700">
                    Special Pricing
                  </h2>
                  {/* Render special pricing */}
                  {specialPricing.map((item: any) => (
                    <div key={item.id} className="mb-2 flex justify-between">
                      <p className="text-gray-700">
                        Amount: <span className="font-bold">{item.amount}</span>
                      </p>
                      <p className="text-gray-500">
                        Time: {formatTime(item.fromTime)} -{" "}
                        {formatTime(item.toTime)}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {/* Render Weekend Pricing unless it's a special day */}
              {!isSpecialDay(selectedDate) && (
                <div className="md:w-[35em] w-full bg-gradient-to-b from-white via-pink-100 to-pink-100 shadow-lg rounded-lg px-4 py-2 border border-pink-400 my-3">
                  <h2 className="text-xl font-semibold mb-2 text-pink-700">
                    Weekend Pricing
                  </h2>
                  {/* Render weekend pricing */}
                  {weekendPricing.map((item: any) => (
                    <div key={item.id} className="mb-2 flex justify-between">
                      <p className="text-gray-700">
                        Amount: <span className="font-bold">{item.amount}</span>
                      </p>
                      <p className="text-gray-500">
                        Time: {formatTime(item.fromTime)} -{" "}
                        {formatTime(item.toTime)}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className=" col-span-2 pl-10 w-full flex justify-center items-center  md:max-h-[40em] max-h-[30em]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              // // Assuming 'selectedTimezone' contains the desired timezone
              // const bookingDateTime = moment.tz(`${fromDate}T${selectedTime}:00:00`, selectedTimezone).toISOString();
              const bookingDateTime = `${
                selectedDate.getDate() === currentTime.getDate() &&
                selectedDate.getMonth() === currentTime.getMonth()
                  ? fromDate
                    ? fromDate
                    : formatDate
                  : formatDate
              }T${selectedTime}:00:00+0530`;

              if (!values.fullName && phone.length === 12) {
                toast.error("Please fill in all fields");
                return;
              }

              if (!phone) {
                toast.error("Please enter a mobile number");
                return;
              }

              if (!selectedTime) {
                toast.error("Please select a time slot");
                return;
              }
              await axiosURLS
                .post("/onlineBooking", {
                  firstName: values.fullName,
                  contactDetail: {
                    mobileNumber: `+${phone}`,
                    email: values.email,
                  },
                  bookingTime: bookingDateTime,
                  whatsAppOptIn: true,
                  arenaRef: {
                    id: selectedOption.name,
                    name: selectedOption.name,
                  },
                  bookingMode: "ONLINE",
                  bookingStatus: "ONLINE_WAITING",
                  onlineBookingStatus: "BOOKED",
                  bookedForMinutes: bookedForMinutes,
                })
                .then(({ data }) => {
                  actions.setSubmitting(false);

                  // Reset the form after successful submission
                  actions.resetForm();
                  setPhone("");
                  toast.success("Booked slot successfully");
                  setBookingDetails({
                    id: data.userFriendlyBookingId,
                    name: data.firstName,
                    mobile: data.contactDetail.mobileNumber,
                    startTime: data.bookingTime,
                  });

                  // Fetch availability data again after successful booking
                  // return axiosURLS.post("/onlineBooking/getAvailability", {
                  //   fromDate: fromDate,
                  //   toDate: toDate,
                  //   arenaId: selectedOption.id,
                  // });
                })
                // .then((response) => {
                //   setAvailabilityData(response.data.bookingsAvailabilityInfo);
                //   setFirstEffectCompleted(true);
                // })
                .catch((err) => {
                  console.log("error");
                });

              actions.setSubmitting(false);
              setSelectedSlots([]);
              setIsOpenSubmit(true);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="max-w-md bg-[#111421]  rounded-lg shadow-md px-10 py-4 ">
                {/* className="max-w-md mx-auto py-12 px-10 bg-white rounded-lg shadow-md" */}
                <div className="flex items-center mb-4 text-[#d3145a] gap-1 text-xl">
                  <RiContactsBook2Line className="  text-white" />
                  <h1 className=" capitalize text-white">
                    Reserve Your Slot Now
                  </h1>
                </div>

                <div className="mb-4">
                  <Input
                    label="Full Name"
                    placeholder="Enter Your full name"
                    name="fullName"
                    type="text"
                    required
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-red-500 text-sm mt-2"
                  />
                </div>
                <div className="flex items-center flex-wrap mb-3">
                  <label
                    htmlFor=""
                    className="text-white text-md mb-3 min-w-[150px]"
                  >
                    Mobile Number
                    <span className="text-red-500"> * </span>
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    placeholder="enter your mobile number"
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label="Email"
                    placeholder=" Enter Your Email"
                    name="email"
                    type="text"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-2"
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-[#d3145a] text-[#fded21] hover:bg-[#d3145ada] font-bold py-2 px-4  mb-2 rounded focus:outline-none focus:shadow-outline"
                  >
                    {isSubmitting ? "SUBMITTING..." : "RESERVE NOW"}
                  </button>
                </div>
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500 text-sm mt-2"
                />
              </Form>
            )}
          </Formik>
        </div>

        {isOpen && <ArenaModal onSelectOption={handleOptionSelect} />}
        {isOpenSubmit && (
          <ConfirmModal
            onClose={closeModal}
            //@ts-ignore
            bookingDetails={bookingDetails}
          />
        )}
      </motion.div>
    </>
  );
};

export default MainContent;
