// App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Option from "./pages/Option";
import MainContent from "./pages/MainContent";
import Status from "./pages/Status";
import { Toaster } from "react-hot-toast";

function App() {
  const [activeComponent, setActiveComponent] = useState("Option");

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActiveComponent("Option");
    } else if (path === "/booking") {
      setActiveComponent("Booking");
    } else if (path === "/status") {
      setActiveComponent("Status");
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              activeComponent === "Option" ? (
                <Option />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/booking" element={<MainContent />} />
          <Route path="/status" element={<Status />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
      <Toaster />
    </>
  );
}

export default App;
