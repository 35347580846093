import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Option() {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const images = ["/cricket_bg.jpg", "/Stadium-img.jpg"];
    let loadedCount = 0;

    const checkImagesLoaded = () => {
      loadedCount++;
      if (loadedCount === images.length) {
        setShouldRender(true);
      }
    };

    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.onload = checkImagesLoaded;
      img.onerror = checkImagesLoaded;
    });
  }, []);

  if (!shouldRender) {
    return null; // Don't render the component until the images are loaded
  }

  return (
    <div className="flex flex-col sm:flex-row h-screen overflow-hidden">
      <div
        className="sm:w-1/2 bg-cover bg-no-repeat flex-1"
        style={{
          backgroundImage: "url('/cricket_bg.jpg')",
        }}
      >
        <div className="flex justify-center items-center h-full min-h-[200px] sm:min-h-[500px]">
          <div className="bg-black bg-opacity-70 py-6 rounded-md text-white px-10 sm:px-20">
            <h2 className="text-3xl font-bold mb-4">Bay Booking</h2>
            <Link
              to="/booking"
              className="bg-[#d3145a] text-[#fded21] flex justify-center font-bold py-2 px-4 rounded hover:bg-[#d3145adf] focus:outline-none focus:bg-[#d3145adf]"
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
      <div
        className="sm:w-1/2 bg-cover bg-no-repeat flex-1"
        style={{
          backgroundImage: "url('/Stadium-img.jpg')",
        }}
      >
        <div className="flex justify-center items-center h-full min-h-[200px] sm:min-h-[500px]">
          <div className="bg-black bg-opacity-70 py-6 px-10 sm:px-20 rounded-md text-white">
            <h2 className="text-3xl font-bold mb-4">Check Status</h2>
            <Link
              to="/status"
              className="bg-[#d3145a] text-[#fded21] flex justify-center font-bold py-2 px-4 rounded hover:bg-[#d3145adf] focus:outline-none focus:bg-[#d3145adf]"
            >
              Check Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Option;
