import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();

  const isBookingPage = location.pathname === "/booking";
  const isStatusPage = location.pathname === "/status";

  return (
    <nav className="bg-[#04071B] from-purple-500 to-blue-600 z-10 fixed top-0 w-full p-4">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2 text-white">
            <img
              src="https://six2ocricket.com/wp-content/uploads/2023/01/SIX-LOGO-White.png"
              alt="Logo"
              className="h-12 rounded-md"
            />
            <span className="hidden md:block text-xl text-[#fd21b0] font-semibold">
              Booking
            </span>
          </Link>
          <div className="flex items-center">
            <div className="hidden md:flex space-x-4">
              <Link
                to="/"
                className={`text-white relative ${
                  !isBookingPage && !isStatusPage ? "font-bold" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/booking"
                className={`relative text-white ${
                  isBookingPage ? "font-bold text-yellow-500" : ""
                }`}
              >
                Create-Booking
              </Link>
              <Link
                to="/status"
                className={`relative text-white ${
                  isStatusPage ? "font-bold text-yellow-500" : ""
                }`}
              >
                Booking-Status
              </Link>
            </div>
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <motion.div
          className="md:hidden absolute top-16 left-0 w-full bg-[#04071B] p-4 space-y-2 z-10"
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={variants}
        >
          <Link to="/" className="text-white block" onClick={toggleMenu}>
            Home
          </Link>
          <Link
            to="/booking"
            className={`text-white block ${
              isBookingPage ? "font-bold text-yellow-500" : ""
            }`}
            onClick={toggleMenu}
          >
            Create-Booking
          </Link>
          <Link
            to="/status"
            className={`text-white block ${
              isStatusPage ? "font-bold text-yellow-500" : ""
            }`}
            onClick={toggleMenu}
          >
            Booking-Status
          </Link>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;

// src="https://firebasestorage.googleapis.com/v0/b/promptopiadem.appspot.com/o/six2o.jpeg?alt=media&token=a5bc45bb-3467-43a2-be8f-6a46841230a6"
