import React from "react";
import { ReactNode } from "react";

interface ButtonProps {
  onClick: () => void;
  className?: string;
  children?: ReactNode;
  label: string;
}

const Button = ({ onClick, className, label }: ButtonProps) => {
  return (
    <button onClick={onClick} className={`p-2 rounded m-2  ${className}`}>
      {label}
    </button>
  );
};

export default Button;
