import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axiosURLS from "../services/Api";
import PhoneInput from "react-phone-input-2";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Status() {
  const [status, setStatus] = useState<any>([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [userFriendlyId, setUserFriendlyId] = useState("");
  const [searchCriteria, setSearchCriteria] = useState({
    mobileNumber: "",
    userFriendlyId: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchCriteria.mobileNumber || searchCriteria.userFriendlyId) {
      setLoading(true);
      axiosURLS
        .post("/onlineBooking/search", {
          mobileNumber: `${
            mobileNumber ? `+${searchCriteria.mobileNumber}` : ""
          }`,
          userFriendlyId: searchCriteria.userFriendlyId,
        })
        .then((response) => {
          setStatus(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error fetching availability data:", error);
          setLoading(false);
        });
    }
  }, [searchCriteria]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!mobileNumber && !userFriendlyId) {
      toast.error("Please fill Booking Id or Mobile Number");
      return;
    }
    setSearchCriteria({ mobileNumber, userFriendlyId });
  };

  const handleMobileFocus = () => {
    setUserFriendlyId("");
  };

  const handleBookingIdFocus = () => {
    setMobileNumber("");
  };

  const formatTime = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${day}/${monthName}/${year}`;
  };

  const renderBooking = (booking: any) => {
    const bookingDate = new Date(booking.bookingTime);
    const currentDate = new Date();
    const isCurrentMonth =
      bookingDate.getMonth() === currentDate.getMonth() &&
      bookingDate.getFullYear() === currentDate.getFullYear();

    return (
      <div
        key={booking.id}
        className={`bg-pink-100 rounded-md p-4 mb-4 shadow-md w-80 ${
          isCurrentMonth ? "border-4 border-yellow-500" : ""
        }`}
      >
        <p className="text-sm">
          <strong>Booking ID:</strong>{" "}
          <span className="text-blue-600">{booking.userFriendlyBookingId}</span>
        </p>
        <p className="text-sm">
          <strong>Booking Status:</strong>{" "}
          <span className="text-red-600">{booking.bookingStatus}</span>
        </p>
        <p className="text-sm">
          <strong>Online Booking Status:</strong>{" "}
          <span className="text-green-600">{booking.onlineBookingStatus}</span>
        </p>
        <p className="text-sm">
          <strong>Booking Time:</strong>{" "}
          <span className="text-gray-600">
            {formatTime(new Date(booking.bookingTime))} ,{" "}
            {formatDate(new Date(booking.bookingTime))}
          </span>
        </p>
      </div>
    );
  };

  const renderNoBookingMessage = () => {
    if (!searchCriteria.mobileNumber && !searchCriteria.userFriendlyId) {
      return "";
    }
    if (searchCriteria.mobileNumber && searchCriteria.userFriendlyId) {
      return "No bookings found for this month.";
    }
    if (searchCriteria.userFriendlyId) {
      return "Booking ID does not exist.";
    }
    if (searchCriteria.mobileNumber) {
      return "Mobile number does not match any booking.";
    }
    return "";
  };

  const renderBookings = () => {
    if (loading) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="w-80">
              <SkeletonTheme baseColor="#cac5c5" highlightColor="#ada8a8">
                <Skeleton height={150} />
              </SkeletonTheme>
            </div>
          ))}
        </div>
      );
    }

    if (!Array.isArray(status) || status.length === 0) {
      return <p className="text-gray-600">{renderNoBookingMessage()}</p>;
    }

    const currentDate = new Date();

    const filteredBookings = status
      .filter((booking) => {
        const bookingDate = new Date(booking.bookingTime);
        return bookingDate >= currentDate; // Include all future dates
      })
      .sort(
        (a, b) =>
          new Date(a.bookingTime).getTime() - new Date(b.bookingTime).getTime()
      ); // Sort from future to past

    if (filteredBookings.length === 0) {
      return <p className="text-gray-600">No future bookings found.</p>;
    }

    return filteredBookings.map((booking) => renderBooking(booking));
  };

  return (
    <>
      <Navbar />
      <motion.div
        className="container pt-24 background-image min-h-[100vh] overflow-y-scroll"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <form onSubmit={handleSubmit} className="max-w-sm mx-auto">
          <div className="modal-content rounded">
            <div className="mb-4">
              <label htmlFor="userFriendlyId" className="block text-gray-700">
                Booking ID
              </label>
              <input
                type="text"
                id="userFriendlyId"
                value={userFriendlyId}
                onFocus={handleBookingIdFocus}
                onChange={(e) =>
                  setUserFriendlyId(
                    e.target.value.substring(0, 15).toUpperCase()
                  )
                }
                className="form-input mt-1 block w-full border pl-2 h-[35px] border-[#CACACA] bg-[#FFFFFF]"
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="border-b border-gray-400 w-full"></div>
            <span className="mx-4 text-gray-600 font-bold">Or</span>
            <div className="border-b border-gray-400 w-full"></div>
          </div>
          <div className="mb-4 mt-2">
            <label htmlFor="mobileNumber" className="block text-gray-700 mb-1">
              Mobile Number
            </label>
            <PhoneInput
              country={"in"}
              value={mobileNumber}
              onFocus={handleMobileFocus}
              placeholder="Enter your mobile number"
              onChange={(mobileNumber) =>
                setMobileNumber(mobileNumber.substring(0, 15))
              }
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#d3145a] text-[#fded21] hover:bg-[#d3145ada] px-4 py-2 rounded"
            >
              SEARCH
            </button>
          </div>
        </form>

        <div className="mt-8 flex flex-wrap justify-center gap-4">
          {renderBookings()}
        </div>
      </motion.div>
    </>
  );
}

export default Status;
