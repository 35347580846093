import React from "react";

interface BookingDetails {
  id: string;
  name: string;
  mobile: string;
  startTime: string;
}

interface ConfirmModalProps {
  onClose: () => void;
  bookingDetails: BookingDetails;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onClose,
  bookingDetails,
}) => {
  const date = new Date(bookingDetails.startTime);

  const formatTime = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    // const [year, month, day] = dateString.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${day}/${monthName}/${year}`;
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md mx-4">
        <h2 className="text-xl font-bold mb-4 text-center border-black border-b">
          Booking Details
        </h2>
        <div className="mb-4 text-md">
          <p className="">
            <strong>Booking ID :</strong>{" "}
            <span className="text-blue-600">{bookingDetails.id}</span>
          </p>
          <p>
            <strong>Name :</strong> {bookingDetails.name.toUpperCase()}
          </p>
          <p>
            <strong>Mobile Number :</strong> {bookingDetails.mobile}
          </p>
          <p>
            <strong>Start Time :</strong> {formatTime(date)} ,{" "}
            {formatDate(date)}
          </p>
        </div>
        <div className="mb-4">
          <p className="text-red-700 font-bold">
            <strong className="text-black font-medium">Note : </strong> Upto
            Five person allowed in a bay
          </p>
        </div>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="bg-[#d3145a] text-[#fded21] hover:bg-[#d3145ada] font-bold py-2 px-4  mb-2 rounded focus:outline-none focus:shadow-outline w-32"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
