import React, { useEffect, useState } from "react";
import { motion, Variants } from "framer-motion";
import axiosURLS from "../services/Api";
import Button from "./CustomButton";
import toast from "react-hot-toast";

interface ModalProps {
  onSelectOption: (option: string) => void;
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const modalVariants: Variants = {
  open: {
    opacity: 1,
    scale: 1,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    scale: 0.8,
    transition: { duration: 0.2 },
  },
};

const ArenaModal = ({ onSelectOption }: ModalProps) => {
  const [selectedOption, setSelectedOption] = useState<any>({
    id: "Lucknow",
    metaData: {
      createdAt: "2023-03-10T12:26:38+0530",
      updatedAt: "2023-04-25T06:25:15+0000",
      version: 3,
      state: "ACTIVE",
    },
    name: "Lucknow",
    address: {
      line1: "Vikash Bhawan",
      line2: "Hazratganj",
      city: "Lucknow",
      state: "Lucknow",
      zipcode: "226010",
      country: "India",
      landmark: "Atal Chowk",
    },
    contactDetail: {
      mobileNumber: "1234567890",
      email: "lko12@gmail.com",
    },
    arenaImageId: null,
  });
  const [ArenaList, setArenaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosURLS
      .post("/arena/search", {})
      .then((response) => {
        setArenaList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error");
        setIsLoading(false);
      });
  }, []);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedArena = ArenaList.find(
      (arena: any) => arena.id === event.target.value
    );
    setSelectedOption(selectedArena ? selectedArena : ""); // Set the ID if selectedArena is not null, otherwise set an empty string
  };

  // Function to handle option selection and close the modal
  const handleOptionSelect = () => {
    if (!selectedOption.name) {
      toast.error("Please select arena option");
    } else {
      onSelectOption(selectedOption);
    }
  };

  return (
    <>
      <motion.div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
        initial="closed"
        animate="open"
        variants={modalVariants}
      >
        <motion.div className="modal">
          <motion.div
            className="modal-content w-96 bg-white rounded shadow-lg"
            initial="closed"
            animate="open"
            variants={modalVariants}
          >
            <h1 className="font-bold text-center py-2">Select Arena</h1>
            {/* Close button */}
            <motion.div className="p-10" variants={itemVariants}>
              <select
                value={selectedOption.name}
                onChange={handleOptionChange}
                className="w-full p-2 mt-2 border rounded"
                disabled={isLoading}
              >
                {isLoading && <option value="">Select an arena...</option>}
                {isLoading ? (
                  <option className="text-gray-500 animate-pulse">
                    Loading...
                  </option>
                ) : (
                  ArenaList.map((item: any) => (
                    <option key={item.id} value={item.name}>
                      {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                    </option>
                  ))
                )}
              </select>
              <motion.div
                className="flex justify-center mt-4"
                variants={itemVariants}
              >
                <Button
                  onClick={handleOptionSelect}
                  className="bg-[#d3145a] w-28 text-[#fded21] hover:bg-[#d3145ada]"
                  label="SELECT"
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default ArenaModal;
